import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const ImageWrapper = styled.div`
  width: ${({ width }) => width};
  @media screen and (max-device-width: 600px) {
    width: ${({ mobileWidth }) => mobileWidth};
  }
  @media screen and (max-device-width: 1024px) {
    width: ${({ tabletWidth }) => tabletWidth};
  }
  @media screen and (min-device-width: 1025px) and (max-device-width: 1280px) {
    width: ${({ netbookWidth }) => netbookWidth};
  }
  @media screen and (min-device-width: 1281px) and (max-device-width: 1366px) {
    width: ${({ notebookWidth }) => notebookWidth};
  }
`

const Image = ({
  fluid,
  fixed,
  title,
  alt,
  className,
  width,
  mobileWidth,
  tabletWidth,
  netbookWidth,
  notebookWidth,
}) => (
  <ImageWrapper
    width={width}
    mobileWidth={mobileWidth}
    tabletWidth={tabletWidth}
    netbookWidth={netbookWidth}
    notebookWidth={notebookWidth}
  >
    <Img fluid={fluid} fixed={fixed} title={title} className={className} alt={alt} />
  </ImageWrapper>
)

Image.propTypes = {
  fluid: PropTypes.object,
  fixed: PropTypes.object,
  width: PropTypes.string,
  mobileWidth: PropTypes.string,
  tabletWidth: PropTypes.string,
  netbookWidth: PropTypes.string,
  notebookWidth: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
}

Image.defaultProps = {
  fluid: null,
  fixed: null,
  title: null,
  width: null,
  className: null,
  mobileWidth: null,
  tabletWidth: null,
  netbookWidth: null,
  notebookWidth: null,
}

export default Image
