import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import trailer from "../../images/trailer-red.svg"
import Button from "../shared/button"

const Img = styled.img`
  height: 350px;
  width: 500px;
  @media screen and (max-device-width: 600px) {
    height: 110px;
    width: 140px;
  }
  @media screen and (max-device-width: 1024px) {
    height: 210px;
    width: 300px;
  }
  @media screen and (min-device-width: 1025px) and (max-device-width: 1366px) {
    height: 350px;
    width: 400px;
  }
`
const Wrapper = styled.div`
  width: 500px;
  @media screen and (max-device-width: 600px) {
    width: 110px;
  }
  @media screen and (max-device-width: 1024px) {
    width: 400px;
  }
  @media screen and (min-width: 1025px) and (max-device-width: 1280px) {
    width: 400px;
  }
  @media screen and (min-device-width: 1025px) and (max-width: 1366px) {
    width: 450px;
  }
`

const ShipperHeader = () => (
  <div className="container flex flex-wrap lg:flex-no-wrap justify-between mt-12 md:mt-32 px-6 mx-auto">
    <div className="w-full lg:w-3/5">
      <h1 className="font-semibold text-blue-100 text-md md:text-lg xl:text-3xl leading-snug">
        Get Your Shipment Where It Needs to Go, Guaranteed.
      </h1>
      <div className="text-sm lg:text-lg font-normal text-blue-100">
        Archerhub connects you with thousands of carriers for reliable capacity
        at competitive rates.{" "}
        <span className="hidden md:inline">
          Plus, our backup recovery plan means you’re covered – no matter what
          happens on the road.
        </span>
      </div>
      <div className="mt-2 lg:mt-5">
        <Link to="/login">
          <Button
            className="bg-red-600 hover:bg-red-700 active:bg-red-800 hover:text-red-100 focus:outline-none focus:shadow-outline text-red-100 md:font-semibold text-md md:text-lg py-1 md:py-2 xl:py-4 px-4 md:px-8 mr-3 lg:mr-6 rounded-full"
            type="button"
          >
            Shipper Login
          </Button>
        </Link>
        <Link to="/signup-shipper">
          <Button
            className="bg-yellow-500 hover:bg-yellow-700 active:bg-yellow-800 hover:text-yellow-100 focus:outline-none focus:shadow-outline text-yellow-800 md:font-semibold text-md md:text-lg py-1 md:py-2 xl:py-4 px-4 md:px-8 rounded-full"
            type="button"
          >
            Ship my Load
          </Button>
        </Link>
      </div>
    </div>
    <div className="w-full lg:w-2/5 hidden lg:block">
      <Wrapper>
        <Img src={trailer} alt="Archerhubs digital freight brokerage for shippers" />
      </Wrapper>
    </div>
  </div>
)

export default ShipperHeader
