import Loadable from "@loadable/component"
import { Router } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import AppStart from "../services/appstart-service"
import "./../index.css"
import Footer from "./footer/footer"
import Header from "./header/header"
import LoginHeader from "./login/login-header"

const LoadableNav = Loadable(() => import("./navigation/navigation"))
const LoadableAltNav = Loadable(() => import("./navigation/alt-navigation"))

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)
  const appStart = new AppStart()
  appStart.initializeApp()
  return (
    <>
      <Router>
        <LoadableAltNav
          path="/login"
          menuLinks={data.site.siteMetadata.menuLinks}
        />
        <LoadableAltNav
          path="/404"
          menuLinks={data.site.siteMetadata.menuLinks}
        />
        <LoadableAltNav
          path="/news"
          menuLinks={data.site.siteMetadata.menuLinks}
        />
        <LoadableAltNav
          path="/news/*"
          menuLinks={data.site.siteMetadata.menuLinks}
        />
        <LoadableAltNav
          path="/bidding"
          menuLinks={data.site.siteMetadata.menuLinks}
        />
        <LoadableAltNav
          path="/carrier_signup"
          menuLinks={data.site.siteMetadata.menuLinks}
        />
        <LoadableAltNav
          path="/review-ratecon"
          menuLinks={data.site.siteMetadata.menuLinks}
        />
        <LoadableAltNav
          path="/signup-success"
          menuLinks={data.site.siteMetadata.menuLinks}
        />
        <LoadableAltNav
          path="/terms"
          menuLinks={data.site.siteMetadata.menuLinks}
        />
        <LoadableNav default menuLinks={data.site.siteMetadata.menuLinks} />
      </Router>
      <Router>
        <LoginHeader path="/login" />
        <LoginHeader path="/news" />
        <LoginHeader path="/news/*" />
        <LoginHeader path="/404" />
        <LoginHeader path="/bidding" />
        <LoginHeader path="/carrier_signup" />
        <LoginHeader path="/review-ratecon" />
        <LoginHeader path="/signup-success" />
        <LoginHeader path="/terms" />
        <Header default siteTitle={data.site.siteMetadata.title} />
      </Router>
      <main>{children}</main>
      <Footer className="flex items-center justify-around" />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
