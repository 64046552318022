import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Button from "../shared/button"
import Image from "../shared/image"

const Wrapper = styled.div`
  width: 350px;
  @media screen and (max-device-width: 600px) {
    width: 110px;
  }
  @media screen and (max-device-width: 1024px) {
    width: 300px;
  }
  @media screen and (min-width: 1025px) and (max-device-width: 1280px) {
    width: 300px;
  }
  @media screen and (min-device-width: 1025px) and (max-width: 1366px) {
    width: 350px;
  }
`
const AboutHeader = ({ title }) => {
  const data = useStaticQuery(graphql`
    query AboutHeaderImageQuery {
      about: file(relativePath: { eq: "about-us.png" }) {
        childImageSharp {
          fluid(maxWidth: 2812, maxHeight: 1520) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="container flex flex-wrap lg:flex-no-wrap w-full justify-between items-center mt-12 md:mt-32 xl:mt-40 px-6 mx-auto">
      <div className="w-full lg:w-1/2 p-2">
        <h1 className="font-semibold text-blue-100 text-4xl lg:text-6xl">
          {title}
        </h1>
        <div className="hidden mt-2 lg:mt-10 mb-5">
          <Button
            className="bg-black hover:bg-gray-700 active:bg-gray-800 hover:text-white focus:outline-none focus:shadow-outline text-white font-bold text-md lg:text-lg py-2 lg:py-4 px-4 lg:px-8 lg:mr-6 rounded-full"
            type="button"
          >
            Contact Us
          </Button>
        </div>
      </div>

      <div className="w-full lg:w-1/2 hidden lg:block">
        <Wrapper>
          <Image
            width="500px"
            netbookWidth="350px"
            tabletWidth="250px"
            fluid={data.about.childImageSharp.fluid}
            title="Archer hub digital freight marketplace for shippers and carriers"
            alt="Archer hub digital freight marketplace for shippers and carriers"
          />
        </Wrapper>
      </div>
    </div>
  )
}

AboutHeader.propTypes = {
  title: PropTypes.string.isRequired,
}

AboutHeader.defaultProps = {
  title: "About Us",
}

export default AboutHeader
