import { Router } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import AboutHeader from "../about-us/about-header"
import CarrierHeader from "../carrier/carrier-header"
import LandingHeader from "../landing/landing-header"
import ShipperHeader from "../shipper/shipper-header"
import RecoveryHeader from "../freight-recovery/recovery-header"
import BgImage from "./background"

const Header = ({ siteTitle, className }) => {
  const data = useStaticQuery(graphql`
    query BackgroundImageQuery {
      hero: file(relativePath: { eq: "hero-blue-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 3807, maxHeight: 2379) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <header className={className}>
      <BgImage
        title="Hero Image"
        alt="Hero Image"
        height="100%"
        mobileHeight="100%"
        fluid={data.hero.childImageSharp.fluid}
        overlayColor="rgba(0, 57, 138, 0.5)"
      >
        <Router>
          <LandingHeader path="/" />
          <CarrierHeader path="/carrier" />
          <ShipperHeader path="/shipper" />
          <ShipperHeader path="/tracking" />
          <ShipperHeader path="/signup-shipper" />
          <AboutHeader path="/about" />
          <AboutHeader title="FAQ" path="/faq" />
          <RecoveryHeader path="/freight-recovery" />
        </Router>
      </BgImage>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  className: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: null,
  className: null,
}

export default Header
