import PropTypes from "prop-types"
import React from "react"

const Button = ({ className, children, type, click, title, disabled }) => (
  <button className={className} type={type} onClick={click} title={title}>
    {children}
  </button>
)

Button.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  children: PropTypes.node,
  click: PropTypes.func,
}

Button.defaultProps = {
  className: null,
  title: null,
  type: null,
  children: null,
  click: null,
}

export default Button
