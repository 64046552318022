import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import trailer from "../../images/trailer-blue.svg"
import Button from "../shared/button"

const Img = styled.img`
  height: 350px;
  width: 500px;
  @media screen and (max-device-width: 600px) {
    height: 110px;
    width: 140px;
  }
  @media screen and (max-device-width: 1024px) {
    height: 230px;
    width: 300px;
  }
  @media screen and (min-device-width: 1025px) and (max-device-width: 1366px) {
    height: 300px;
    width: 400px;
  }
`
const Wrapper = styled.div`
  width: 500px;
  @media screen and (max-device-width: 600px) {
    width: 110px;
  }
  @media screen and (max-device-width: 1024px) {
    width: 400px;
  }
  @media screen and (min-width: 1025px) and (max-device-width: 1280px) {
    width: 400px;
  }
  @media screen and (min-device-width: 1025px) and (max-width: 1366px) {
    width: 450px;
  }
`

const CarrierHeader = () => {
  return (
    <div className="container flex flex-wrap lg:flex-no-wrap w-full justify-between mt-12 md:mt-32 px-5 mx-auto">
      <div className="w-full lg:w-3/5 p-2">
        <h1 className="font-semibold text-blue-100 text-md md:text-2xl xl:text-3xl leading-normal">
          Maximize Your Miles.
        </h1>
        <div className="lg:block mt-2 lg:mt-10">
          <Link to="/carrier_signup">
            <Button
              className="bg-yellow-500 hover:bg-yellow-700 active:bg-yellow-800 hover:text-yellow-100 focus:outline-none focus:shadow-outline text-yellow-800 md:font-semibold text-md md:text-lg py-1 md:py-2 xl:py-4 px-4 md:px-8 lg:mr-6 rounded-full"
              type="button"
            >
              Start Hauling More
            </Button>
          </Link>
        </div>
      </div>
      <div className="w-full xl:w-2/5 hidden lg:block">
        <Wrapper>
          <Img src={trailer} alt="Carriers can maximize their miles using Archerhub's freight marketplace" />
        </Wrapper>
      </div>
    </div>
  )
}

export default CarrierHeader
