import axios from 'axios'
import Cookies from 'js-cookie'

class HubspotService {
    CreateContact = async(contact) =>{
        let res = await axios.post("https://api.hubapi.com/contacts/v1/contact/?hapikey="+process.env.hb_key, contact);
        return res.data;
    }
    CreateCompany = async(company)=>{
        let res = await axios.post("https://api.hubapi.com/companies/v2/companies?hapikey="+process.env.hb_key, company);
        return res.data;
    }
    Associate = async(companyid,contactid)=>{
        let obj = {
            "fromObjectId": companyid,
            "toObjectId": contactid,
            "category": "HUBSPOT_DEFINED",
            "definitionId": 2
        }
        let res = await axios.put("https://api.hubapi.com/crm-associations/v1/associations?hapikey="+process.env.hb_key, obj);
        return res.data;
    }
    SubmitForm = async(data)=>{
        let obj = {
            fields:data,
            context:{
                hutk:Cookies.get('hubspotutk'),
                pageUri:process.env.uri,
                pageName:"Archerhub"
            }
        }
        let res = await axios.post("https://api.hsforms.com/submissions/v3/integration/submit/6337735/dc2105b8-52e2-4da1-92d8-bbfb85ed4b19", obj);
        return res.data;
    }
    Subscribe = async(email)=>{
        let obj = {
            fields:[{name:"email","value":email}],
            context:{
                hutk:Cookies.get('hubspotutk'),
                pageUri:process.env.uri,
                pageName:"Archerhub"
            }
        }
        let res = await axios.post("https://api.hsforms.com/submissions/v3/integration/submit/6337735/3c0c36fe-d6d9-48f8-b376-df5e9728cbb7", obj);
        return res.data;
    }
}
export default HubspotService
