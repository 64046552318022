import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const Parent = styled.div`
  position: relative;
  background-color: ${({ bc }) => bc};
  overflow: hidden;
  height: 600px;
  @media screen and (min-device-width: 1024px) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    height: 500px;
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    height: 340px;
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    height: 360px;
  }
  @media (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
    height: 350px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    height: 180px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    height: 180px;
  }
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    height: 180px;
  }
`
const BgImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => height};

  & > img {
    object-fit: cover;
    object-position: 0% 0%;
  }
  @media screen and (max-device-width: 600px) {
    height: ${({ mobileHeight }) => mobileHeight};
  }
`
const Content = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`
const BgImage = ({
  fluid,
  title,
  alt,
  height,
  mobileHeight,
  overlayColor,
  children,
  className,
}) => (
  <Parent bc={overlayColor}>
    <BgImg
      fluid={fluid}
      title={title}
      alt={alt}
      height={height}
      mobileHeight={mobileHeight}
    />
    <Content className={className}>{children}</Content>
  </Parent>
)

BgImage.propTypes = {
  fluid: PropTypes.object.isRequired,
  title: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.string,
  mobileHeight: PropTypes.string,
  overlayColor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}
BgImage.defaultProps = {
  height: null,
  mobileHeight: null,
  title: null,
  alt: null,
  overlayColor: "transparent",
  children: null,
  className: null,
}

export default BgImage
