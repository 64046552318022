import React, { Component } from "react"
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import styled from "styled-components"
import HubspotService from "../../services/hubspot-service"
import Button from "../shared/button"
import Input from "../shared/input"

const Bar = styled.form`
  height: 224px;
  margin-bottom: 0;
  overflow-x: hidden;
  background: transparent linear-gradient(94deg, #06a0e4 0%, #0071e9 100%) 0% 0%
    no-repeat padding-box;
`
class MailBar extends Component {
  constructor() {
    super()
    this.hbService = new HubspotService()
  }
  state = { working: false }
  onFormSubmit = event => {
    this.setState({ working: true })
    event.preventDefault()
    let val = this.input.value
    this.subscribe(val)
  }
  subscribe(email) {
    this.hbService.Subscribe(email).then(
      res => {
        this.setState({ working: false, success: true, error: false })
      },
      err => {
        console.log(err)
        this.setState({ working: false, success: false, error: true })
      }
    )
  }
  render() {
    return (
      <Bar className={this.props.className} onSubmit={this.onFormSubmit}>
        <div className="w-full">
          <div className="flex justify-center mb-2 lg:mb-5">
            <h3 className="font-semibold md:text-xl lg:text-2xl text-blue-100">
              Get Industry and Archerhub updates
            </h3>
          </div>
          <div className="flex justify-center">
            <Input
              className="px-3 lg:px-4 py-3 lg:py-4 w-1/2 lg:w-1/3 focus:outline-none focus:shadow-outline rounded rounded-r-none hover:border-blue-400 placeholder-gray-700"
              type="email"
              placeholder="Enter your email"
              refName={input => (this.input = input)}
            />
            <Button
              className="bg-black hover:bg-gray-800 active:bg-gray-900 hover:text-gray-100 focus:outline-none text-gray-100 font-semibold lg:font-bold text-md lg:text-lg py-3 lg:py-4 px-4 rounded rounded-l-none"
              type="submit"
            >
              Subscribe
              {this.state.working === true ? (
                <div className="ml-2">
                  <Loader type="Bars" color="#3182ce" height={30} width={30} />
                </div>
              ) : (
                <></>
              )}
              {this.state.success === true ? (
                <i className="ml-2 icon ion-md-checkmark-circle-outline text-green-600 text-xl"></i>
              ) : (
                <></>
              )}
              {this.state.error === true ? (
                <i className="ml-2 icon ion-md-alert text-red-600 text-xl"></i>
              ) : (
                <></>
              )}
            </Button>
          </div>
        </div>
      </Bar>
    )
  }
}

export default MailBar
