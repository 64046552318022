import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import Button from "../shared/button"
import Image from "../shared/image"

const Wrapper = styled.div`
  width: 500px;
  @media screen and (max-device-width: 600px) {
    width: 110px;
  }
  @media screen and (max-device-width: 1024px) {
    width: 400px;
  }
  @media screen and (min-width: 1025px) and (max-device-width: 1280px) {
    width: 400px;
  }
  @media screen and (min-device-width: 1025px) and (max-width: 1366px) {
    width: 450px;
  }
`
const LandingHeader = () => {
  const data = useStaticQuery(graphql`
    query LandingHeaderImageQuery {
      laptopphone: file(relativePath: { eq: "laptop-phone.png" }) {
        childImageSharp {
          fluid(maxWidth: 2205, maxHeight: 1340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="container mx-auto flex flex-wrap lg:flex-no-wrap justify-between mt-10 md:mt-24 lg:mt-32 px-6">
      <div className="w-full lg:w-3/5">
        <h1 className="font-semibold text-blue-100 text-sm md:text-2xl xl:text-3xl leading-normal">
          The premier digital marketplace for truckload freight where shippers
          and drivers are connected through our mobile app or online platform.
        </h1>
        <div className="block mt-2 lg:mt-10">
          <Link to="/carrier">
            <Button
              className="bg-yellow-500 hover:bg-yellow-700 active:bg-yellow-800 hover:text-yellow-100 focus:outline-none focus:shadow-outline text-yellow-800 md:font-semibold text-sm md:text-lg py-1 md:py-2 xl:py-4 px-4 md:px-8 mr-3 lg:mr-6 mb-6 rounded-full"
              type="button"
            >
              <span className="hidden md:block">Haul with Archerhub</span>
              <span className="md:hidden">Carrier</span>
            </Button>
          </Link>
          <Link to="/shipper">
            <Button
              className="bg-red-600 hover:bg-red-800 active:bg-red-900 hover:text-red-100 focus:outline-none focus:shadow-outline text-red-100 md:font-semibold text-sm md:text-lg py-1 md:py-2 xl:py-4 px-4 md:px-8 mr-3 lg:mr-6 rounded-full"
              type="button"
            >
              <span className="hidden md:block">Ship with Archerhub</span>
              <span className="md:hidden">Shipper</span>
            </Button>
          </Link>
          <br className="hidden xl:block" />
          <Link to="/tracking">
            <Button
              className="bg-transparent text-blue-100 md:font-semibold border border-blue-100 hover:border-transparent hover:bg-blue-800 focus:outline-none focus:shadow-outline active:text-blue-100 active:bg-blue-900 text-sm md:text-lg py-1 md:py-2 xl:py-4 px-4 md:px-8 rounded-full"
              type="button"
            >
              <span className="hidden md:block">Track your Load</span>
              <span className="md:hidden">Track Load</span>
            </Button>
          </Link>
        </div>
      </div>

      <div className="w-full lg:w-2/5 xl:mr-0 hidden lg:block">
        <Wrapper>
          <Image
            width="600px"
            mobileWidth="200px"
            tabletWidth="320px"
            netbookWidth="500px"
            notebookWidth="500px"
            fluid={data.laptopphone.childImageSharp.fluid}
            title="Archer hub's digital freight marketplace app"
            alt="Archer hub's digital freight marketplace app"
          />
        </Wrapper>
      </div>
    </div>
  )
}

export default LandingHeader
