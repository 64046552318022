import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import MailBar from "../mailbar/mailbar"
import Image from "../shared/image"

const Foot = styled.footer`
  height: 337px;
  overflow-x: hidden;
  background: #000000 0% 0% no-repeat padding-box;
`

const Footer = ({ className }) => {
  const data = useStaticQuery(graphql`
    query FooterImageQuery {
      google: file(relativePath: { eq: "google-play.png" }) {
        childImageSharp {
          fluid(maxWidth: 135, maxHeight: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      apple: file(relativePath: { eq: "apple-store.png" }) {
        childImageSharp {
          fluid(maxWidth: 135, maxHeight: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <MailBar className="flex items-center" />
      <Foot className={className}>
        <div className="container mx-auto flex flex-wrap lg:flex-no-wrap">
          <div className="w-full flex justify-around lg:hidden">
            <div className="flex-shrink">
              <h3 className="logo text-white text-xl lg:text-2xl">
                <Link to="/">Archerhub</Link>
              </h3>
              <ul className="list-none text-gray-600 lg:text-md font-light lg:font-semibold">
                <li>© {new Date().getFullYear()} Archerhub</li>
                <li>
                  <Link to="/terms">
                    <span className="text-white">Terms</span> &{" "}
                    <span className="text-white">Privacy</span>
                  </Link>
                </li>
                <li>
                  <span
                    className="text-white text-sm"
                    role="img"
                    aria-labelledby="email"
                  >
                    📧{" "}
                  </span>
                  <a
                    className="text-sm text-gray-600 hover:text-blue-500"
                    href="mailto:info@archerhub.com?Subject=Enquiry"
                    target="_top"
                  >
                    support@archerhub.com
                  </a>
                </li>
                <li>
                  <span
                    className="text-white text-sm"
                    role="img"
                    aria-labelledby="phone"
                  >
                    📱{" "}
                  </span>
                  <a
                    className="text-sm text-gray-600 hover:text-blue-500"
                    href="tel:303-542-7110"
                  >
                    303-542-7110
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/archerhub/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-white hover:text-blue-700"
                  >
                    <i className="icon ion-logo-facebook"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/archerhub"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-white mx-3 hover:text-blue-700"
                  >
                    <i className="icon ion-logo-linkedin"></i>
                  </a>
                  <a
                    href="https://twitter.com/ArcherHub"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-white hover:text-blue-700"
                  >
                    <i className="icon ion-logo-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-blue-500 uppercase font-normal lg:font-bold text-md lg:text-lg">
                Company
              </h4>
              <ul className="list-none text-white text-md font-light lg:font-semibold">
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/news">News</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="hidden lg:block flex-auto lg:pl-20">
            <h3 className="uppercase logo text-white text-xl lg:text-2xl">
              <Link to="/">Archerhub</Link>
            </h3>
            <ul className="list-none text-gray-600 lg:text-md font-light lg:font-semibold">
              <li>© {new Date().getFullYear()} Archerhub</li>
              <li>
                <Link to="/terms">
                  <span className="text-white">Terms</span> &{" "}
                  <span className="text-white">Privacy</span>
                </Link>
              </li>
              <li>
                <span className="text-white text-sm">Email Us: </span>
                <a
                  className="text-sm text-gray-600 hover:text-blue-500"
                  href="mailto:info@archerhub.com?Subject=Enquiry"
                  target="_top"
                >
                  support@archerhub.com
                </a>
              </li>
              <li>
                <span className="text-white text-sm">Call Us: </span>
                <a
                  className="text-sm text-gray-600 hover:text-blue-500"
                  href="tel:303-542-7110"
                >
                  303-542-7110
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/archerhub/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-white text-lg hover:text-blue-400"
                >
                  <i className="icon ion-logo-facebook"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/archerhub"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-white text-lg mx-3 hover:text-blue-400"
                >
                  <i className="icon ion-logo-linkedin"></i>
                </a>
                <a
                  href="https://twitter.com/ArcherHub"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-white text-lg hover:text-blue-400"
                >
                  <i className="icon ion-logo-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="hidden lg:block flex-auto">
            <h4 className="text-blue-500 uppercase font-normal lg:font-bold text-md lg:text-lg">
              Locations
            </h4>
            <ul className="list-none text-white text-md font-light lg:font-semibold">
              <li>Archerhub Denver</li>
              <li className="text-sm text-gray-600 font-thin">
                1860 Blake St. Suite 620
              </li>
              <li className="text-sm text-gray-600 font-thin">
                Denver, CO 80202
              </li>
            </ul>
            <ul className="list-none text-white text-md  font-light lg:font-semibold">
              <li>Archerhub Sacramento</li>
              <li className="text-sm text-gray-600 font-thin">
                3009 Douglas Blvd. Suite 110
              </li>
              <li className="text-sm text-gray-600 font-thin">
                Roseville, CA 95661
              </li>
            </ul>
          </div>
          <div className="hidden lg:block flex-auto">
            <h4 className="text-blue-500 uppercase font-normal lg:font-bold text-md lg:text-lg">
              Company
            </h4>
            <ul className="list-none text-white text-md  font-light lg:font-semibold">
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              {/* <li>
      <Link to="/">Careers</Link>
      </li> */}
              <li>
                <Link to="/news">News</Link>
              </li>
              {/* <li>
      <Link to="/">Press</Link>
      </li>
      <li>
      <Link to="/">Contact Us</Link>
      </li> */}
            </ul>
          </div>

          <div className="w-full flex justify-around lg:hidden mt-5">
            <div className="pr-5">
              <h4 className="text-blue-500 uppercase font-normal lg:font-bold text-md lg:text-lg">
                Solutions
              </h4>
              <ul className="list-none text-white text-md font-light lg:font-semibold">
                <li>
                  <Link to="/carrier">Carriers</Link>
                </li>
                <li>
                  <Link to="/shipper">Shippers</Link>
                </li>
                <li>
                  <Link to="/tracking">Track Shipment</Link>
                </li>
              </ul>
            </div>
            <div className="pl-5">
              <h4 className="text-blue-500 uppercase font-normal lg:font-bold text-md lg:text-lg">
                Get the App
              </h4>
              <ul className="list-none mt-1">
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.archeratlantic.archerdriverapp"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Image
                      fluid={data.google.childImageSharp.fluid}
                      title="Google Play Store"
                      width="135px"
                      mobileWidth="90px"
                      tabletWidth="100px"
                    />
                  </a>
                </li>
                <li className="mt-3">
                  <a
                    href="https://apps.apple.com/jm/app/archerhub/id1466242683"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Image
                      fluid={data.apple.childImageSharp.fluid}
                      width="135px"
                      mobileWidth="90px"
                      tabletWidth="100px"
                      title="Apple Store"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="hidden lg:block flex-auto mt-5 lg:mt-0">
            <h4 className="text-blue-500 uppercase font-normal lg:font-bold text-md lg:text-lg">
              Solutions
            </h4>
            <ul className="list-none text-white text-md font-light lg:font-semibold">
              <li>
                <Link to="/carrier">Carriers</Link>
              </li>
              <li>
                <Link to="/shipper">Shippers</Link>
              </li>
              <li>
                <Link to="/tracking">Track Shipment</Link>
              </li>
            </ul>
          </div>
          <div className="hidden lg:block flex-auto mt-5 lg:mt-0 ml-16 lg:ml-0">
            <h4 className="text-blue-500 uppercase font-normal lg:font-bold text-md lg:text-lg">
              Download the App
            </h4>
            <ul className="list-none mt-1">
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.archeratlantic.archerdriverapp"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Image
                    fluid={data.google.childImageSharp.fluid}
                    title="Google Play"
                    width="132px"
                    mobileWidth="90px"
                  />
                </a>
              </li>
              <li className="mt-3">
                <a
                  href="https://apps.apple.com/jm/app/archerhub/id1466242683"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Image
                    fluid={data.apple.childImageSharp.fluid}
                    width="130px"
                    mobileWidth="90px"
                    title="Apple Store"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Foot>
    </>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

Footer.defaultProps = {
  className: null,
}

export default Footer
