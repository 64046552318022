import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Parent = styled.input`
 
`

const Input = ({ className, placeholder, type, refName }) => (
  <Parent className={className} placeholder={placeholder} type={type} ref={refName}></Parent>
)

Input.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  refName:PropTypes.func,
}

Input.defaultProps = {
  className: null,
  placeholder: null,
  type: null,
  refName:null,
}

export default Input
