import md5 from 'md5';
import axios from 'axios';
import ls from 'local-storage'
import Base64 from 'Base64';

class AppStart{
    initializeApp(){
        const usr = ls.get('wauid');
        if (!usr) {
            this.getDefaultUser();
        } 
    }

    getDefaultUser(){
        const uid = Base64.atob('YXJjaGVyd2Vi');
        const pid = md5(Base64.atob('QXJjaGVyV2ViQDE='));
        axios.get(process.env.apiurl + 'Users/Login/' + uid + '/' + pid).then(res => {
            let user = Object.assign(res.data);
          user.TokenHeader = res.headers.tokenheader;
          user.Auth = Base64.btoa(user.UserName + ':' + user.TokenHeader);
          ls.set('wauid', Base64.btoa(JSON.stringify(user)));
          })
    }
}
export default AppStart